<template>
    <div>

    <div class="text-center mt-5" v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" >
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>
    <div v-if="!cargando">
        <form @submit.prevent="accionformulario" method="POST" enctype="multipart/form-data"  class="row mb-5">

        <div class="col-md-12 mb-3">
            <span class="h4 titulosg" v-if="accion == 'nueva'">Nueva Publicación</span>
            <span class="h4 titulosg" v-if="accion == 'editar'">Editar Publicación</span>
        </div>
        <div class="col-md-9">
            
            <div class="mb-2">             
                <label for="titulo" class="form-label">Título de la Publicación: <span class="text-danger">*</span> </label>
                <input type="text" required class="form-control" id="titulo" v-model="datospublicacion.titulo" >
            </div>
            <div class="mb-3">
                <label for="resumen" class="form-label">Resumen:</label>
                <textarea class="form-control" id="resumen" rows="3" maxlength="200" v-model="datospublicacion.resumen"></textarea>
                <span class="text-secondary">Máximo 200 Caracteres </span>
            </div>                          

            <div id="app" class="mb-3">
               
                <editor
                    v-model="datospublicacion.texto"
                    api-key="fjq4jztxghzswoxpb6olz475m2jrssujbs1d09f2xr6r0aj3"
                    :init="{
                        height: 550,
                        menubar: true,
                        language: 'es',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code wordcount imagetools '
                        ],
                        toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat '
                    }"
                    
                    />                   
            </div>

            <div class="mb-2">
                <strong> Categoría: </strong> (Etiquetas)
                <div class="mt-2 d-flex">
                    <div class="card p-2 me-2 mb-2" v-for="item in categorias" :key="item.idcontenidocategoria">
                    <div class="form-check" >
                        <input class="form-check-input"  :id="'cp'+item.idcontenidocategoria" type="checkbox" :value="item.idcontenidocategoria" name="categoriascontenido" v-model="categoriascontenidoseleccionadas" >
                        <label class="form-check-label" :for="'cp'+item.idcontenidocategoria">
                            {{item.nombrecategoria}}
                        </label>
                    </div>
                    </div>

                </div>
            </div>
            
            <div class="mb-4 d-flex">
                <div>
                    <input type="checkbox" class="btn-check" value="1" id="anclaje" true-value="1" false-value="0" v-model="datospublicacion.anclaje" autocomplete="off" >
                    <label class="btn btn-outline-primary me-3" for="anclaje"><i class="fas fa-thumbtack"></i></label> 
                </div>
                <div>
                    Fijar contenido en la parte superior  
                </div>
            </div>

            <div class="border-top pt-3">
                <button class="btn btn-primary" type="submit" :disabled="mostrar"> <i class="fas fa-save"></i> Guardar  </button>
                <button class="btn btn-light" type="button"  @click="cancelaryregresar">  Cancelar </button>
            </div>

        </div>  

        <div class="col-md-3">
            
            <!-- IMAGEN DE PORTADA -->
            <div class="mb-3">
                    <strong>Imagen de Portada:</strong> 

                    <div class="image-upload border rounded mt-1 p-3 bg-white " style="cursor:pointer; cursor: hand">
                        <label for="image" class="d-flex">
                                <i class="fas fa-cloud-upload-alt fa-2x text-primary me-3"></i>
                                <div>
                                    Seleccionar Imagen<br>
                                    <span class="text-secondary" style="font-size: 11px;"> Tamaño Máximo 2 Mb </span>
                                </div>
                        </label>            
                        <input type="file" id="image" name="image" @change="getImage" accept="image/*" ref="inputFile">
                    </div>

                    <div class="alert alert-danger mt-2" v-if="alertatamanio">
                        El tamaño de la imagen sobrepasar el tamaño recomendado
                    </div>

                    <img :src="archivospublicos+'/contenidos/'+datospublicacion.fotoanterior" v-if="datospublicacion.fotoanterior !== null" class="mb-1 img-fluid" alt="">
 
                    <img :src="render" class="mb-1 img-fluid" alt="">
                    {{uploadPercentage}}
                    <span v-if="render!==null" class="btn btn-link btn-sm" @click="render=null; this.$refs.inputFile.value='null'; "> <i class="fas fa-trash"></i> Eliminar </span> 
            </div>


            <div class="card mb-3 ">
                <div class="card-header align-middle">
                    <strong>Publicación</strong>
                </div>
                <div class="card-body">


                    <label for="estado" class="form-label">Estado: <span class="text-danger">*</span></label>
                    <select name="" id="" class="form-select mb-2" v-model="datospublicacion.estadocontenido">
                        <option value="1">Activo</option>
                        <option value="2">Inactivo</option>
                        <option value="3">Borrador</option>
                    </select>


                    <label for="desde" class="form-label">  Desde: <span class="text-danger">*</span> </label>
                    <input type="date" required class="form-control" id="desde" v-model="datospublicacion.fechainicio">
                    
                    <label for="hasta" class="form-label">Hasta:</label>
                    <input type="date" class="form-control" id="hasta"  v-model="datospublicacion.fechafin">
 
                    <div class="mt-3 mb-1">
                    <strong> Publicar en: <span class="text-danger">*</span></strong> 
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" required   value="1"  checked v-model="datospublicacion.webintranet">
                        <label class="btn btn-outline-secondary" for="btnradio1">Web</label>
                        
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" required  value="2"  v-model="datospublicacion.webintranet">
                        <label class="btn btn-outline-secondary" for="btnradio2">Intranet</label>
                        
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio3" required  value="3" v-model="datospublicacion.webintranet">
                        <label class="btn btn-outline-secondary" for="btnradio3">Todos</label>
                    </div>

                </div>
            </div>

            <div class="mb-3" v-if="datospublicacion.webintranet > 1">
  
                <strong>Visible en las Sedes:</strong>
                    <div class="list-group mt-2">
                        <label class="list-group-item list-group-item-action" v-for="item in sedes" :key="item.idempresasede">
                            <input class="form-check-input" type="checkbox" name="sedes" v-model="sedeseleccionada" :value="item.idempresasede" :id="'sede'+item.idempresasede">
                            {{item.nombreempresasede}}
                        </label>
                    </div>
             
            </div>

            <div class="mb-3" v-if="datospublicacion.webintranet > 1">
  
                    <strong>Grupos:</strong>
                    <div class="list-group mt-2">
                        <label class="list-group-item list-group-item-action" v-for="item in tipopersonas" :key="item.idpersonascategoria">
                            <input class="form-check-input" type="checkbox" name="tipospersonasseleccionadas" v-model="tipospersonasseleccionadas" :value="item.idpersonascategoria" :id="'tipo'+item.idpersonascategoria">
                            {{item.nombrecategoria}}
                        </label>      
                    </div>
                 
            </div>

        </div>

    </form>
    </div>
 
</div>
</template>

<script>

import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'
import moment from 'moment'
import Editor from '@tinymce/tinymce-vue'

import { useRouter, useRoute } from 'vue-router'

export default {
    components: {
     'editor': Editor
   },
    props:['id','accion'],
    setup(props) {
        
        const cargando = ref(true)
        const router = useRouter()
        const route = useRoute()

        const mostrar = ref(false)
        const accion = ref(props.accion)
        const uploadPercentage = ref(null)

        const sedes = ref({})
        const sedeseleccionada = ref([])
        
        const tipopersonas = ref([])
        const tipospersonasseleccionadas = ref([])

        const categorias = ref([])
        const categoriascontenidoseleccionadas = ref([])

        const fotoportada = ref(null)
        const render = ref(null)
        const alertatamanio = ref(false)

        const datospublicacion = reactive({
            idcontenido: null,
            titulo: '',
            resumen: '',
            texto: '',
            fechainicio: '',
            fechafin: '',
            webintranet: 1,
            anclaje: 0,
            estadocontenido: 1,
            fotoanterior: null
        })

        const ListarSedesPermitidas = () => {
            axios.get('/api/sedesautorizadas').then(response => {
                sedes.value = response.data
            }).catch(error=>{ console.log(error) })
        }

        const ListaCategoriasPersonas = () => {
            axios.get('/api/tiposdepersonas').then(response => {
                tipopersonas.value = response.data
            }).catch(error=>{ console.log(error) })
        }

        const ListarCategoriasContenidos = () => {
            axios.get('/api/categoriapublicaciones').then(response => {
                categorias.value = response.data
                cargando.value = false;

            }).catch(error=>{ console.log(error) })
        }

 
        const consultarpublicacion = () => {

            axios.get('/api/infopublicacion/'+props.id).then( response => {

                datospublicacion.idcontenido = response.data.idcontenido
                datospublicacion.titulo = response.data.titulo
                datospublicacion.resumen = response.data.resumen
                datospublicacion.texto = response.data.texto
                datospublicacion.fechainicio = response.data.fechainicio
                datospublicacion.fechafin = response.data.fechafin
                datospublicacion.webintranet = response.data.webintranet
                datospublicacion.anclaje = response.data.anclaje
                datospublicacion.estadocontenido = response.data.estadocontenido
                datospublicacion.fotoanterior = response.data.fotoportada

                //Asignación de los complementos en la edición sedes, categorias y tipos de personas
                // for (var i = 0; i < response.data.sedes.length; i++) {
                //      sedeseleccionada.value[i]= response.data.sedes[i].idempresasede
                // }

                // for (var i = 0; i < response.data.categorias.length; i++) {
                //      categoriascontenidoseleccionadas.value[i]= response.data.categorias[i].idcontenidocategoria
                // }

                // for (var i = 0; i < response.data.tipospersonas.length; i++) {
                //      tipospersonasseleccionadas.value[i]= response.data.tipospersonas[i].idpersonascategoria
                // }
 

                cargando.value = false;

            }).catch( error => console.log(error))
        }

        const accionformulario = () => {
            
                mostrar.value=true
                let url = ''  
                if(props.accion=='nueva'){   url ='/api/guardarpublicacion'     }
                if(props.accion=='editar'){  url = '/api/actualizarpublicacion' } 
 
                axios.post(url, { data: datospublicacion, sedes: sedeseleccionada.value, terceros: tipospersonasseleccionadas.value, categorias:  categoriascontenidoseleccionadas.value }).then(response => {        
                                        

                        if(response.data !== "Error"){    
                            
                            if(fotoportada.value == null){  
                                    swal("Bien!", "Información Ingresada Correctamente!", "success") 
                                    router.push({ path: '/infopublicacion/'+response.data })
                            }
                            else { 
                                CargarFoto(response.data)
                            }

                        }else{                        
                            swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");                        
                        }

                }).catch(error => { console.log(error)})
        }


        const getImage = (event) => {
          
            const tamanio = parseInt((event.target.files[0].size)/1024)
            
            if(tamanio > 2048){
                alertatamanio.value = true
                mostrar.value = true
                render.value = null
            }else{            
                alertatamanio.value = false
                render.value = URL.createObjectURL(event.target.files[0]);
                fotoportada.value = event.target.files[0];
                mostrar.value = false
            }
        }

        const CargarFoto = (idpublicacion) => {

                    let data = new FormData();

                    data.append('fotoanterior', datospublicacion.fotoanterior);
                    data.append('foto', fotoportada.value);
                    data.append('idpublicacion', idpublicacion);

                    let config = {
                        header : {
                        'Content-Type' : 'multipart/form-data'
                        },
                        onUploadProgress: function( progressEvent ) { uploadPercentage.value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )) }.bind(this)
                    }

                    axios.post('/api/crearoeditarfotoportada', data, config).then(response => {        
                                        
                    if(response.data=="ok"){
                        swal("Bien!", "Información Ingresada Correctamente!", "success");   
                        router.push({ path: '/infopublicacion/'+idpublicacion })
                    
                        }else{                        
                        swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");         
                    }

                    }).catch(error => { console.log(error)})

                        mostrar.value=false

        }

        const cancelaryregresar = () => {  router.go(-1) }
        

        onMounted(() => {
            ListarSedesPermitidas()
            ListaCategoriasPersonas()
            ListarCategoriasContenidos()
            datospublicacion.fechainicio = moment().format('yyyy-MM-DD')     
            
            	if(props.id !== null && props.accion == "editar")
				{
					consultarpublicacion()
				}
        })


        return {

                cargando,
                mostrar,
                accion,
                datospublicacion,
                fotoportada,
                render,
                alertatamanio,
                sedes,
                sedeseleccionada,
                tipopersonas,
                tipospersonasseleccionadas,
                categorias,
                categoriascontenidoseleccionadas,
 

                uploadPercentage,

                //funciones
                accionformulario,
                getImage,
                CargarFoto,
                cancelaryregresar 
 
            }
    }
}
</script>
<style>
.image-upload > input
{
    display: none;
}

.image-upload img
{
    width: 30px;
    cursor:pointer; cursor: hand
}
</style>
 
